import Vue from 'vue'
import App from './App.vue'
import router from './router'

import './plugins/element.js'
import "./assets/css/global.css"

import './assets/fonts/iconfont.css'// 导入字体图标
import NetworkworkRequest from "./plugins/service/HttpServer.js"

// import Url from "./plugins/service/UrlConfig"
import CustomLoading from "@/components/CustomLoading"
import Video from "@/components/video"

Vue.config.productionTip = false
Vue.prototype.$NetworkworkService=NetworkworkRequest
// Vue.prototype.$Url=Url

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')

Vue.use(CustomLoading);
Vue.use(Video);