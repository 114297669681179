import Vue from 'vue'
import {
    Button,
    Input,
    Message ,
    Card,
    Row,
    Col,
    Image,
    Loading,
    Tabs,
    TabPane,
    
} from 'element-ui'

Vue.use(Input)
Vue.use(Button)
Vue.use(Card)
Vue.use(Row)
Vue.use(Col)
Vue.use(Image)
Vue.use(Loading)
Vue.use(Tabs)
Vue.use(TabPane)


Vue.prototype.$message = Message