module.exports = {
    isEmpty: isEmpty,
}

function isEmpty(obj) {
    if (typeof(obj) == 'number' || typeof(obj) == 'boolean') {
        return false
    }
    if (typeof obj == "undefined" || obj == null || obj.trim() == "") {
        return true;
    } else {
        return false;
    }
}