<template>
  <div class="home_container">
    <div class="header_wrap">
      <el-tabs v-model="activeName" type="card" @tab-click="handleTabsClick">
        <el-tab-pane label="URL" name="URL">
          <span slot="label"
            ><img class="tab-img" src="../assets/link.svg" /> URL</span
          >
        </el-tab-pane>

        <el-tab-pane label="Google Images" name="Google">
          <span slot="label"
            ><img class="tab-img" src="../assets/google.svg" /> Google</span
          >
        </el-tab-pane>
        <el-tab-pane label="Instagram Images" name="Instagram">
          <span slot="label" fill="#cd0000"
            ><img class="tab-img" src="../assets/ins.svg" /> Instagram</span
          >
        </el-tab-pane>
        <el-tab-pane
          label="Instagram Images"
          name="Instagram-pro"
          class="instagram-pro-wrap"
        >
          <div slot="label" fill="#cd0000" class="pro-wrap">
            <img class="tab-img" src="../assets/ins.svg" />
            Instagram
            <span class="pro-tag">bulk</span>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Instagram Images" name="Pin-Video">
          <span slot="label" fill="#cd0000"
            ><img class="tab-img" src="../assets/pinterest.svg" /> Pin
            Video</span
          >
        </el-tab-pane>

        <el-tab-pane
          label="Instagram Images"
          name="Pin-Video-pro"
          class="instagram-pro-wrap"
        >
          <div slot="label" fill="#cd0000" class="pro-wrap">
            <img class="tab-img" src="../assets/pinterest.svg" />
            Pin Video
            <span class="pro-tag">bulk</span>
          </div>
        </el-tab-pane>
      </el-tabs>

      <el-input
        v-model="searchKeywords"
        v-show="
          activeName !== 'Instagram-pro' && activeName !== 'Pin-Video-pro'
        "
        @input="handleChange"
        clearable
        :placeholder="inputPlaceHolder"
      >
        <el-button
          slot="append"
          icon="el-icon-search"
          @click="toSearch"
        ></el-button>
      </el-input>

      <div
        class="pro-input-wrap"
        v-show="activeName == 'Instagram-pro' || activeName == 'Pin-Video-pro'"
      >
        <el-input
          v-model="searchKeywords"
          @input="handleChange"
          clearable
          type="textarea"
          :placeholder="inputPlaceHolder"
        >
        </el-input>
        <el-button icon="el-icon-search" @click="toSearch"></el-button>
      </div>

      <div class="err_msg" v-show="isShowError">
        {{isShowError?'No images found.You can send it to
                support[#]imgdownloader.com.We will check it for you.':' '}}
      </div>

      <el-button
        v-if="
          allPics.length > 1 &&
          srcList[0] &&
          !srcList[0].isVideo &&
          activeName != 'Instagram-pro'
        "
        type="primary"
        @click="download2ZIP"
        :disabled="packageAllPics"
        :loading="isSearching"
        >{{ isSearching ? "Processing" : "Download All" }}
      </el-button>
    </div>

    <div v-show="allPics.length > 0" style="padding-bottom: 50px">
      <div class="pics-wrap">
        <!--<el-row :gutter="20"  v-show="activeName=='URL'||activeName=='Google'||(srcList[0]&&!srcList[0].isVideo)">-->
        <el-row :gutter="20">
          <el-col
            :xs="24"
            :sm="12"
            :md="8"
            :lg="6"
            :xl="6"
            v-for="(parentItem, index) in srcList"
            :key="index"
          >
            <div class="flex_vertical">
              <el-image
                :src="parentItem | getDataKey(activeName)"
                fit="scale-down"
                :preview-src-list="prepareViewSrc(index)"
              >
                <div
                  slot="error"
                  class="image-slot flex_vertical fail_tip_wrap"
                >
                  load fail
                  <i class="iconfont icon-tupiancuowu down_icon"></i>
                </div>
              </el-image>

              <el-button
                type="secondary"
                v-show="!parentItem.isVideo"
                @click="downloadSinglePic(parentItem)"
              >
                download<i class="iconfont icon-xiazai down_icon"></i>
              </el-button>

              <a
                :href="parentItem.videourl"
                target="_blank"
                v-show="parentItem.isVideo"
              >
                <el-button type="secondary">
                  download<i class="iconfont icon-xiazai down_icon" />
                </el-button>
              </a>
            </div>
          </el-col>
        </el-row>

        <!--<e6-video v-show="srcList[0]&&srcList[0].isVideo" :url="srcList[0]?srcList[0].videourl:''"-->
        <!--:media-data-source="mediaDataSource"></e6-video>-->

        <!--<div class="flex_vertical" v-show="srcList[0]&&srcList[0].isVideo">

                    <el-image :src="srcList[0]?srcList[0].thumburl:''" fit="scale-down"
                              :preview-src-list="prepareVideoViewSrc()">

                        <div slot="error" class="image-slot  flex_vertical fail_tip_wrap">
                            load fail
                            <i class="iconfont icon-tupiancuowu down_icon"></i>
                        </div>

                    </el-image>

                    <a :href="srcList[0]?srcList[0].videourl:''" target="_blank">
                        <el-button type="secondary">
                            download<i class="iconfont icon-xiazai down_icon"></i>
                        </el-button>
                    </a>
                </div>-->
      </div>
      <el-button
        v-show="allPics.length > 1"
        type="primary"
        @click="loadmore"
        :disabled="allPics.length == srcList.length"
        >Load More
      </el-button>
    </div>

    <custom-loading
      :visible.sync="isRequestPicList"
      :downloadInfo="downloadInfo"
      :text="loadingText"
    />
  </div>
</template>

<style scoped lang="scss" rel="stylesheet/scss">
.el-button--primary {
  background: #687ded;
  color: #fff;
  border: 6px solid transparent;
  transition: 0.4s ease-in-out;
  border-radius: 10px;
  min-height: 20px;
  font-weight: bold;
  padding: 10px 40px;
  font-size: 1rem;
  will-change: transform, box-shadow;
}

.el-button--primary:focus,
.el-button--primary:hover {
  background: #687ded !important;
  border-color: #687ded !important;
}

.el-button--primary:active {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
  transition: 0.4s ease-in-out;
}

.el-button--secondary {
  background-color: #fff;
  color: #14234b;
  padding: 5.5px 30px;
  min-height: 20px;
  line-height: 20px;
  transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s;
  font-weight: 200;
  text-decoration: none;
  vertical-align: middle;
  outline: none;
  display: inline-block;
  position: relative;
  text-align: center;
  border-radius: 4px;
  overflow: hidden;
  will-change: transform, box-shadow;
  margin-bottom: 20px;
}

.home_container {
  height: 100%;
  box-sizing: border-box;
  position: relative;
  padding-bottom: 50px;

  .header_wrap {
    /*height: 160px;*/
    width: 100%;
    z-index: 2000;
    padding: 40px 20px 0 20px;
    box-sizing: border-box;

    .err_msg {
      font-size: 18px;
      color: red;
      height: 20px;
      text-align: left;
      padding: 20px 0;
    }

    .el-input {
      margin-bottom: 30px;
    }
  }
}

.pro-input-wrap {
  display: flex;

  .el-button {
    background-color: #f5f7fa;

    /deep/ .el-icon-search {
      color: #909399;
    }
  }
}

.pics-wrap {
  padding: 60px 20px 20px 20px;

  .el-row {
    margin-bottom: 50px;
  }
}

.el-image {
  width: 220px;
  height: 220px;
  border-radius: 10px;
  margin-bottom: 20px;
  background-color: #eeeeee;
}

.el-image /deep/ .fail_tip_wrap {
  height: 100%;
  background-color: #f5f5f5;
  justify-content: center;
  color: #666666;
  line-height: 1.5em;
}

.down_icon {
  margin-left: 5px;
}

/deep/ .el-tabs__header {
  margin: 0px;
}

/deep/ .el-tabs--card > .el-tabs__header {
  border-bottom: none;
}

/deep/ .el-tabs__item.is-active {
  color: #687ded;
}

.tab-img {
  width: 15px;
  height: 15px;
  color: #cd0000;
}

/deep/ .el-tabs--card > .el-tabs__header .el-tabs__item {
  position: relative;
}

.pro-wrap {
  .pro-tag {
    right: -26px;
    box-sizing: content-box;
    border-radius: 20px;
    height: 20px;
    width: 35px;
    background-color: #21af5a;
    display: inline-block;
    line-height: 20px;
    font-size: 11px;
    color: #ffffff;
  }
}
</style>

<script>
import TextUtil from "../plugins/TextUtils";
import FileSaver from "file-saver";
import JSZip from "jszip";

import CustomLoading from "../components/CustomLoading/src/main";

export default {
  components: {
    CustomLoading,
  },
  filters: {
    getDataKey(value, arg1) {
      return value.thumburl;
    },
  },
  data() {
    return {
      searchKeywords: "",
      isSearching: false,
      allPics: [],
      srcList: [],
      startPageIndex: 0,
      endPageIndex: 12,
      isRequestPicList: false,
      loadingText: "Requesting... Please wait for one minute...",
      packageAllPics: false,
      isShowError: false,
      activeName: "URL",
      inputPlaceHolder: "imgdownloader.com",
      mediaDataSource: {
        type: "mp4", // 文件类型
        isLive: false, // 是否直播
        hasAudio: true, // 是否有音频
        hasVideo: true, // 是否有视频
      },
      downloadInfo: "",
      loginStatus: false,
      subscribeType: 0,
      // preViewSrcList: []
      baseUrl: `https://access.imgdownloader.com`,
      subUrl: "https://member.imgdownloader.com",
      preActiveName: "URL",
      loadingArr: [
        "(1/5) Sending request and preparing the crawler...",
        "(2/5) Start loading page and analyze images...",
        "(3/5) Scrolling down to find more images...",
        "(4/5) Analyzing and packaging...",
        "(5/5) Ready for showing images if images finded...",
      ],
      loadingIndex: 0,
      
    };
  },

  created() {
    console.log("------------------------------updated-------------------");
    // this.getPicList()
    this.loginStatus = Boolean(localStorage.getItem("loginStatus") || false); //true/false
    //订阅类型3种不同类型的用户可使用的次数不同   包括Free  0，premium 1，pro 2.
    this.subscribeType = parseInt(localStorage.getItem("plan") || "0");
  },

  updated() {
    console.log(
      "****************************************created*************************"
    );
  },

  methods: {
    toSearch() {
      if (TextUtil.isEmpty(this.searchKeywords)) {
        this.$message.info(
          "Please enter a valid url or keywords before searching the images"
        );
        return;
      } else if (
        this.activeName == "Instagram-pro" ||
        this.activeName == "Pin-Video-pro"
      ) {
        if (!this.loginStatus) {
          this.$message.error(
            "Please sign up and login your account to use this bulk feature."
          );
          return;
        } else if (this.checkCredits()) {
          this.$message.error(
            "You have used up all credits. Please upgrade your subscription plan or contact us to get more credits."
          );
          return;
        } else {
          let content = this.searchKeywords;
          let arr = content.split("\n");
          if (arr.length > 10) {
            this.$message.info(
              "Please enter no more than 10 Instagram copied links. "
            );
            return;
          }
        }
      }
      this.request2Search();
    },

    checkCredits() {
      let downloadCount = localStorage.getItem("leftCound");
      return downloadCount == 0;
    },
    request2Search() {
      this.loadingIndex = 0;
      this.isSearching = false;
      this.getPicList();
    },

    handleTabsClick(tab, event) {
      if (this.preActiveName !== tab.name) {
        this.searchKeywords = "";
      }
      this.preActiveName = tab.name;
      this.allPics = [];
      this.srcList = [];

      this.isShowError = false;
      switch (this.activeName) {
        case "URL":
          this.inputPlaceHolder = "imgdownloader.com";
          break;
        case "Google":
          let endMsg = "";
          if (!this.loginStatus) {
            endMsg = "Up to 50 images for free users";
          } else if (this.subscribeType == 0) {
            //Free
            endMsg = "Up to 50 images for free users";
          } else if (this.subscribeType == 1) {
            //premium
            endMsg = "Up to 200 images for premium users";
          } else if (this.subscribeType == 2) {
            //premium
            endMsg = "Up to 400 images for pro users";
          }
          this.inputPlaceHolder =
            "Enter a keyword like tiger to fetch all Google images. ( " +
            endMsg +
            " )";
          break;
        case "Instagram":
          this.inputPlaceHolder =
            "https://www.instagram.com/p/CNxu3PAsz2S/?utm_source=ig_web_copy_link";
          break;
        case "Instagram-pro":
          this.inputPlaceHolder =
            "https://www.instagram.com/p/CNxu3PAsz2S/?utm_source=ig_web_copy_link\nhttps://www.instagram.com/p/CNxu3PAsz2S/?utm_source=ig_web_copy_link\nhttps://www.instagram.com/p/CNxu3PAsz2S/?utm_source=ig_web_copy_link\nhttps://www.instagram.com/p/CNxu3PAsz2S/?utm_source=ig_web_copy_link";
          break;
        case "Pin-Video":
          this.inputPlaceHolder =
            "https://www.pinterest.jp/pin/43065740176118046/";
          break;
        case "Pin-Video-pro":
          this.inputPlaceHolder =
            "https://www.pinterest.jp/pin/43065740176118046\nhttps://www.pinterest.jp/pin/43065740176118046\nhttps://www.pinterest.jp/pin/43065740176118046\nhttps://www.pinterest.jp/pin/43065740176118046";
          break;
        case "":
          break;
      }
    },

    updateLoadingMsg() {
      if (this.loadingIndex == 5) {
        clearInterval(this.intervalId);
        this.loadingIndex = 0;
      } else {
        this.loadingText = this.loadingArr[this.loadingIndex];
        this.loadingIndex++;
      }
      return this.updateLoadingMsg;
    },
    async getPicList() {
      this.allPics = [];
      this.srcList = [];
      // this.preViewSrcList=[]

      this.startPageIndex = 0;
      this.endPageIndex = 12;
      this.isRequestPicList = true;
      this.intervalId = setInterval(this.updateLoadingMsg(), 10000);

      let paramsData;
      let keyword = this.searchKeywords;
      let url = "";
      console.log(this.activeName);
      switch (this.activeName) {
        case "URL":
          url = "/ImageDownloadPro";
          paramsData = {
            // url: 'http://imglarger.com',
            url: keyword.startsWith("http") ? keyword : "http://" + keyword,
          };
          break;
        case "Google":
          url = "/GetGoogleImage";
          paramsData = {
            keyword,
            plan: this.subscribeType || 0,
          };
          break;
        case "Instagram":
          /*
           * https://www.instagram.com/tv/CJye32mHscR/?utm_source=ig_web_copy_link
           * https://www.instagram.com/p/CLcwj1IhSz-/?utm_source=ig_web_copy_link
           *
           * https://www.instagram.com/p/CLRrfTcnOd8/?utm_source=ig_web_copy_link   图片
           * https://www.instagram.com/tv/CN2Ly9thiSS/?utm_source=ig_web_copy_link   视频
           * */
          url = "/GetInstagramImage";
          paramsData = {
            url: keyword.startsWith("http") ? keyword : "http://" + keyword,
          };
          break;

        case "Instagram-pro":
          url = "/GetInstagramImagePro";

          let arr = this.searchKeywords.split("\n");
          arr = arr.map((item) => {
            return item.startsWith("http") ? item : "http://" + item;
          });

          paramsData = {
            url: arr,
          };

          break;
        case "Pin-Video":
          /**
           * https://www.pinterest.com/pin/684265737136191893
           * */
          url = "/GetPinterestVedio";
          let targetData = [
            keyword.startsWith("http") ? keyword : "http://" + keyword,
          ];

          paramsData = {
            url: targetData,
          };
          break;
        case "Pin-Video-pro":
          url = "/GetPinterestVedio";
          let arr1 = this.searchKeywords.split("\n");
          arr1 = arr1.map((item) => {
            return item.startsWith("http") ? item : "http://" + item;
          });

          paramsData = {
            url: arr1,
          };
          break;
      }
    //  this.selectServeUrl()

      let res = await this.$NetworkworkService.toRequest({
        url: this.baseUrl + url,
        params: {
          ...paramsData,
          isInPageLoading: true,
        },
      });

      if (res.isSuccess) {
        let { data } = res;

        this.isShowError = data.length == 0;
        if (
          !this.isShowError &&
          (this.activeName == "Instagram-pro" ||
            this.activeName == "Pin-Video-pro")
        ) {
          this.request2Sub();
        }
        if (this.activeName == "Google") {
          if (
            (!this.loginStatus || this.subscribeType == 0) &&
            data.length > 50
          ) {
            data = data.splice(0, 50);
          } else if (this.subscribeType == 1 && data.length > 200) {
            data = data.splice(0, 200);
          } else if (this.subscribeType == 2 && data.length > 400) {
            data = data.splice(0, 400);
          }
        }
        this.allPics = data;
        let startIndex = this.startPageIndex;
        let endIndex = this.endPageIndex;
        let counts = data.length;

        if (counts < 12) {
          this.srcList.push(...data);
        } else {
          let arr1 = data.slice(startIndex, endIndex);
          this.srcList.push(...arr1);
        }
      } else {
        this.isShowError = true;
      }
      this.isRequestPicList = false;
      this.isSearching = false;
    },

    selectServeUrl() {
      let random = Math.ceil(Math.random() * 10);
      let remainder = random % 2;
      console.log(`随机数是${random}余数是${remainder}`);
      this.baseUrl= `https://access${this.activeName.includes('instagram')?'':remainder==0?'':'1'}.imgdownloader.com`
    },

    loadmore() {
      let { endPageIndex, srcList, allPics } = this;
      let startIndex = endPageIndex;
      let end = startIndex + 12;
      let endIndex = end > allPics.length ? allPics.length : end;
      this.srcList.push(...allPics.slice(startIndex, endIndex));
      // this.preViewSrcList = this.srcList
      this.startPageIndex = startIndex;
      this.endPageIndex = endIndex;
    },

    async downloadSinglePic(picPath) {
      clearInterval(this.intervalId);

      this.isRequestPicList = true;
      this.loadingText = "downloading";
      // let res = await this.load2DownloadPic(this.activeName != "Instagram" ? picPath.originalurl : picPath.isVideo ? picPath.videourl : picPath.originalurl)
      let res = await this.load2DownloadPic(picPath.originalurl);

      if (res.isSuccess) {
        this.isRequestPicList = false;
        let { img, type } = res.data;
        type = type.toLowerCase();
        if (["gif", "png", "jpeg", "jpg", "webp", "svg"].includes(type)) {
          this.base64ToBlob(img, type);
        } else {
          this.$message.error("Can not download now");
        }
      } else {
        this.isRequestPicList = false;
      }
    },
    load2DownloadPic(url) {
      return this.$NetworkworkService.toRequest({
        url: this.baseUrl + "/GetImageDetail",
        params: { url, isInPageLoading: true },
      });
    },
    //base64转blob
    base64ToBlob(data, type) {
      let base64EncodeType = `data:image/${type};base64,`;
      data = base64EncodeType + data.replace(/-/g, "+");
      const regex = new RegExp("^data:([^/]+/([^;]+));base64");
      // 可能还需要一些校验
      const b64Data = data.replace(regex, "$1,$2").split(",");
      const contentType = b64Data[0];
      // 解码
      const raw = window.atob(b64Data[2]);
      const rawLength = raw.length;
      let imageName = new Date().getTime();
      const fileName = `${imageName}.${b64Data[1]}`;
      let uInt8Array = new Uint8Array(rawLength);
      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }
      const blob = new Blob([uInt8Array], { type: contentType });
      FileSaver.saveAs(blob, fileName);
    },
    async download2ZIP() {
      /*if (this.activeName == "URL") {
                    if (!this.loginStatus) {
                        this.$message.error('Please sign up and login your account to  download all images.');
                        return
                    }
                    if (this.checkCredits()) {
                        this.$message.error('You have used up all credits. Please upgrade your subscription plan or contact us to get more credits.');
                        return
                    }

                }*/
      try {
        clearInterval(this.intervalId);
        let zip = new JSZip();
        this.isRequestPicList = true;
        this.loadingText = "Packaging and Downloading";
        let picTotalLength = this.allPics.length;
        this.downloadInfo = `0/${picTotalLength}`;
        let currentIndex = 0;
        for (let i = 0; i < picTotalLength; i++) {
          let imageUrl = this.allPics[i];
          // let res = await this.load2DownloadPic(this.activeName != "Instagram" ? imageUrl : imageUrl.isVideo ? imageUrl.videourl : imageUrl.url)
          let res = await this.load2DownloadPic(imageUrl.originalurl);

          if (res.isSuccess) {
            let { img, type } = res.data;
            let imageName = new Date().getTime();
            type = type.toLowerCase();
            if (["gif", "png", "jpeg", "jpg", "webp", "svg"].includes(type)) {
              zip.file("HelloIMG" + imageName + "." + type, img, {
                base64: true,
              });

              // this.base64ToBlob(img, type)
            } else {
              // this.$message.error('暂时不能下载')
            }
            currentIndex++;
            this.downloadInfo = `${currentIndex}/${picTotalLength}`;
          } else {
            currentIndex++;
            this.downloadInfo = `${currentIndex}/${picTotalLength}`;
          }
        }

        this.downImg(zip);
      } catch (err) {
        console.log("batch error");
        console.log("err", err);
      }
    },
    downImg(zip) {
      this.isRequestPicList = false;
      this.downloadInfo = "";
      if (this.activeName == "URL" && this.loginStatus) {
        this.request2Sub();
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          let fileName = "downloadAll.zip";
          FileSaver.saveAs(content, fileName);
        });
    },
    handleChange(e) {
      this.isShowError = false;
    },
    prepareViewSrc(index) {
      // console.log(`找第${index}几张`)
      let arr = [];
      let i = 0;
      for (i; i < this.srcList.length; i++) {
        let item = this.srcList[i + index];
        // arr.push(this.activeName=='Instagram'?item.originalurl:item.originalurl)
        arr.push(item.originalurl);
        if (i + index >= this.srcList.length - 1) {
          index = 0 - (i + 1);
        }
      }
      return arr;
    },
    prepareVideoViewSrc() {
      let arr = [];
      if (this.srcList.length > 0) {
        arr.push(this.srcList[0].originalurl);
      }
      return arr;
    },

    async request2Sub() {
      if (!this.loginStatus) return;

      let res = await this.$NetworkworkService.toRequest({
        url: this.subUrl + "/api/User/UpdateDownloadCount",
        params: {
          isInPageLoading: true,
        },
      });
      if (res.isSuccess) {
        let { data } = res;
        localStorage.setItem("leftCound", data.downloadCount);
      } else {
      }
    },
  },
};
</script>