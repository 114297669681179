import axios from "axios"
import {
    Loading
} from "element-ui";

let mLoading
let mShowLoading = true;

//service循环遍历输出不同的请求方法
let instance = axios.create({
    // baseURL: "https://access.imgdownloader.com",
    // baseURL: "api",
    timeout: 1000 * 100,

})


// 拦截器的添加
// 请求拦截器
instance.interceptors.request.use(config => {
    if (mShowLoading) {
        mLoading = Loading.service({
            fullscreen: true
        })
    }
    let loginStatus = localStorage.getItem("loginStatus") || false
    if (loginStatus) {
        var token = 'Bearer ' + localStorage.getItem("token");
        config.headers.Authorization = token
    }
    console.log(config)
    console.log("Request interceptor-success")
    return config
}, err => {
    // 请求错误
    // Toast.clear()
    // Toast('请求错误，请求稍后重试')
    console.log("Request interceptor-faile")
})


// 响应拦截器
instance.interceptors.response.use(res => {
    // 请求成功
    // Toast.clear()
    console.log("Response interceptor-success")
    console.log(res)
    return res.data
}, msg => {
    // Toast.clear()
    // Toast('请求错误，请求稍后重试')
    console.log("Response interceptor-fail" + msg)
    if (msg.code == 'ECONNABORTED') {
        // originalRequest._retry = true
        // return axios.request(originalRequest);
        msg = "请求超时，请稍后尝试"
    }
    return Promise.reject(msg)
})


/*function request(requestParams) {
    //为了避免同步中的判断这改成promise
    return new Promise((resolve, reject) => {
        let res = toRequest(requestParams).then(
            res => {
                console.log("网络层总处理成功")
                console.log(res)
                let {status} = res.meta;
                if (status == 200) {
                    resolve(res.data)
                } else {
                    reject()
                }
            }
        ).catch(
            error => {
                console.log("网络层总处理失败")
                console.log(error)
                reject()
            }
        )

    })


}*/

async function toRequest(requestParams) {

    /**
     *
     * params, //请求参数get:url;put;post,patch(data),delete:url
     isFormData = false, //标识是不是form-data
     config = {}, //配置参数
     url = api.url, //针对需要地址上的id用“/”直接拼的情况
     */
    let {
        url
    } = requestParams;
    let method = requestParams.method || "post"
    let params = requestParams.params || {}
    let isFormData = requestParams.isFormData || false
    let config = requestParams.config || {}

    mShowLoading = true
    console.log("request")
    if (requestParams.params && requestParams.params.isInPageLoading) {
        mShowLoading = false
        delete requestParams.params.isInPageLoading
    }

    let newParams = {}
    //content-Type是否是form-data的判断
    if (params && isFormData) {
        newParams = new FormData();
        for (let i in params) {
            newParams.append(i, params[i])
        }
    } else {
        newParams = params
    }

    // 不同请求的判断
    let response = {}; // 请求的返回值
    if (method === 'put' || method === 'post' || method === 'patch') {
        try {
            response = await instance[method](url, newParams, config)
            return processResponse(response)
        } catch (err) {
            console.log("请求中的catch2")
            console.log("请求中的catch2" + err)
            if (mShowLoading) {
                mLoading.close()
            }
            // return Promise.reject(err)
            return ""
        }
    } else if (method === 'delete' || method === 'get') {
        config.params = newParams
        try {
            response = await instance[method](url, config)
            return processResponse(response)

        } catch (err) {
            console.log("请求中的catch2")
            console.log("请求中的catch2" + err)
            // return Promise.reject(err)
            if (mShowLoading) {
                mLoading.close()
            }
            return err
        }

    }


}

function processResponse(response) {
    console.log("请求中的try")
    console.log(response)
    let status = response.status || response.code;
    status = parseInt(status)
    if (mShowLoading) {
        mLoading.close()
    }
    if (status == 200) {
        return {
            isSuccess: true,
            data: response.data
        }; // 返回响应值
    } else {
        // return Promise.reject()
        return response.msg
    }
}

export default {
    toRequest
}