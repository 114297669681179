<template>
    <div v-show="visible" class="load_mask">

        <rotate-square2/>
        <span class="load_msg">{{text}}</span>
        <span class="down-info">{{downloadInfo}}</span>


    </div>
</template>

<script>
    import {RotateSquare2} from 'vue-loading-spinner'
    //https://github.com/nguyenvanduocit/vue-loading-spinner
    export default {
        name: "CustomLoading",
        props: {
            visible: {
                type: Boolean,
                default: false
            },
            text: {
                type: String,
                default: ""
            },
            downloadInfo:{
                type: String,
                default: ""
            }

        },
        components: {
            RotateSquare2,
        },
    }

</script>

<style scoped>
    .load_mask {
        position: absolute;
        z-index: 2000;
        background-color: rgba(255, 255, 255, .9);
        margin: 0;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transition: opacity .3s;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .load_msg {
        margin-top: 5px;
        color: #666;
        font-size: 15px;
    }

    .spinner::after {
        background: #687ded;
    }

    .down-info{
        color: #687ded;
        margin-top: 10px;
        font-weight: 1000;
    }

</style>