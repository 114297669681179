/*
 * @Description:
 * @Author: sunxiaodong
 * @Date: 2020-09-21 08:58:39
 * @LastEditors: sunxiaodong
 * @LastEditTime: 2020-09-24 15:29:32
 */

import Video from "./src/main.vue";

Video.install = function(Vue) {
  Vue.component(Video.name, Video);
};

export default Video;
