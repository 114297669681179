<!--
 * @Description: 视屏组件
 * @Author: sunxiaodong
 * @Date: 2019-11-19 16:28:43
 * @LastEditors: sunxiaodong
 * @LastEditTime: 2020-11-26 09:06:47
 -->
<template>
  <video
    :id="'videoElementFlv' + _uid"
    class="e6-video"
    autoplay
    :preload="preload"
    :muted="muted"
    :controls="controls"
  ></video>
</template>

<script>
// HTML 5 video 视频标签全属性详解
// https://www.cnblogs.com/kiter/archive/2013/02/25/2932157.html

import flvjs from "flv.js/dist/flv.js";

// const flvjs = require("e6yun-ui/src/utils/flv.js");

// flv.js 配置属性
// https://github.com/bilibili/flv.js/blob/master/docs/api.md
const MEDIA_DATA_SOURCE = {
  type: "flv", // 文件类型，因为公司的视频基本上是 flv, 且主要用于直播类型
  isLive: true, // 是否直播
  hasAudio: false, // 是否有音频
  hasVideo: true // 是否有视频
  // cors: true,
  // withCredentials: false
};

const CONFIG = {
  // enableWorker: true, // 暂时注销改参数，应为 IE 不支持
  enableStashBuffer: false,
  stashInitialSize: 128,
  autoCleanupSourceBuffer: true
};

const MAX_ERROR = 5;

export default {
  name: "E6Video",

  props: {
    url: {
      type: [String, Object],
      default() {
        return { flv: "", rtmp: "" };
      }
    },

    // preload 属性用于定义视频是否预加载,属性有三个可选择的值：none、metadata、auto。如果不使用此属性，默认为auto。
    preload: {
      type: String,
      default: "auto",
      validator: function(value) {
        return ["none", "metadata", "auto"].indexOf(value) !== -1;
      }
    },

    // muted 属性设置或返回视频是否应该被静音（关闭声音）。
    muted: {
      type: Boolean,
      default: true
    },

    // Controls属性用于向浏览器指明页面制作者没有使用脚本生成播放控制器，需要浏览器启用本身的播放控制栏。
    controls: {
      type: Boolean,
      default: true
    },

    // 是否开启容错
    failover: {
      type: Boolean,
      default: true
    },

    // 容错次数
    failoverCount: {
      type: Number,
      default: 6
    },

    // flv 属性
    mediaDataSource: {
      type: Object,
      default() {
        return {};
      }
    },
    // flv 配置
    config: {
      type: Object,
      default() {
        return {};
      }
    }
  },

  data() {
    return {
      // isSupportedFlv: false,
      videoPlayer: null,
      pushStream: false, // 当前时刻是否推流
      reload_num: 0, // 视频错误时，重新加载了多少次
      error_num: 0
    };
  },

  watch: {
    url: {
      handler() {
        this.play();
      },
      deep: true
    },
    mediaDataSource: {
      handler() {
        this.play();
      },
      deep: true
    }
  },

  mounted() {
    this.play();
  },

  beforeDestroy() {
    this.destroy();
  },

  methods: {
    // 播放
    play() {
      // 切换视频之前首先销毁前一个视屏
      this.destroy();

      if (typeof this.url === "string" && this.url) {
        this.useFlv(this.url);
      }
      if (typeof this.url === "object" && this.url.flv) {
        this.useFlv(this.url.flv);
      }
    },

    // 暂停
    pause() {
      if (this.videoPlayer) {
        this.videoPlayer.pause();
      }
    },

    // 重新播放
    load() {
      this.destroy();
      this.play();
    },

    // 播放完成之后的回调
    ended(el) {
      el.addEventListener(
        "ended",
        () => {
          this.$emit("ended");
        },
        false
      );
    },

    // 销毁 flvjs
    destroy() {
      if (this.videoPlayer) {
        this.videoPlayer.unload();
        this.videoPlayer.detachMediaElement();
        this.videoPlayer.destroy();
        this.videoPlayer = null;
      }
    },

    // 播放 flv 视屏源
    useFlv(url) {
      // 创建视屏
      let el = this.getElement();

      this.videoPlayer = flvjs.createPlayer(
        Object.assign({ url }, MEDIA_DATA_SOURCE, this.mediaDataSource),
        Object.assign({}, CONFIG, this.config)
      );

      // 只有是直播的情况下才执行以下逻辑
      if (this.videoPlayer._config.isLive) {
        // 捕获到视频播放错误时的操作
        this.flvError();

        // 是否开启容错机制
        if (this.failover) {
          this.flvFailover();
        }
      }

      this.ended(el);

      this.videoPlayer.attachMediaElement(el);
      this.videoPlayer.load();
    },

    // 返回视频实例
    player() {
      return this.videoPlayer;
    },

    // 获取dome元素
    getElement() {
      return document.getElementById(`videoElementFlv${this._uid}`);
    },

    // 获取当前视频流的状态
    getCurrentStream() {
      return this.pushStream;
    },

    // 获取当前已播放时间
    getCurrentTime() {
      let currentTime = 0;
      if (this.videoPlayer && this.videoPlayer.currentTime) {
        currentTime = this.videoPlayer.currentTime;
      }
      return currentTime.toFixed();
    },

    // 视频在播放中时的错误处理
    flvFailover() {
      let last_decode_frame = 0; // 视频流的累加
      let err_num = 0; // 错误次数

      this.videoPlayer.on(flvjs.Events.STATISTICS_INFO, info => {
        // 初始阶段，等待推流
        if (info.decodedFrames === 0) {
          err_num++;
          if (err_num > 10) {
            if (this.failoverCount > this.reload_num) {
              ++this.reload_num;
              console.log(`第${this.reload_num}次重新播放`);
              this.play();
            } else {
              this.destroy();
              console.error("视频播放失败");
              this.$emit("error");
            }
          }
          this.pushStream = false;
        } else {
          // 当前流和上次流相同，说明没有推流，反之视频正常播放
          if (last_decode_frame !== info.decodedFrames) {
            err_num = 0;
            this.reload_num = 0;
            last_decode_frame = info.decodedFrames;
            this.pushStream = true;
          } else {
            err_num++;
            if (err_num > 10) {
              if (this.failoverCount > this.reload_num) {
                ++this.reload_num;
                console.log(`第${this.reload_num}次重新播放`);
                this.play();
              } else {
                this.destroy();
                console.error("视频播放失败");
                this.$emit("error");
              }
            }
            this.pushStream = false;
          }
        }
      });
    },

    // 播放失败，不管发生任何错误，都尝试播放5次
    flvError() {
      this.videoPlayer.on(flvjs.Events.ERROR, () => {
        if (MAX_ERROR > this.error_num) {
          ++this.error_num;
          console.log(`第${this.error_num}次重新播放`);
          this.play();
        } else {
          this.destroy();
          console.error("视频播放失败");
          this.$emit("error");
        }
      });
    }
  }
};
</script>
